import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { H2, H3 } from "../styled/headers"
import { Button } from "./Button"

const StyledWrapper = styled.div`
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;

    ${H3} {
        margin-bottom: 5px;
    }

    ${breakpoints.sm} {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 40px;

        ${H3} {
            margin-bottom: 0;
        }
    }
`

const ButtonWrapper = styled.div`
    display: none;
    ${breakpoints.sm} {
        display: block;
    }
`

const Headline = ({ subheader, header, buttonSettings }: props) => {
    return (
        <StyledWrapper>
            <div>
                <H3 dangerouslySetInnerHTML={{ __html: subheader }} />
                <H2 dangerouslySetInnerHTML={{ __html: header }} />
            </div>
            {buttonSettings && (
                <ButtonWrapper>
                    <Button
                        isLink
                        fullWidth
                        outline
                        icon="arrow"
                        href={buttonSettings.href}
                    >
                        {buttonSettings.text}
                    </Button>
                </ButtonWrapper>
            )}
        </StyledWrapper>
    )
}

export default Headline

interface props {
    subheader: string
    header: string
    buttonSettings?:
        | {
              text: string
              href: string
          }
        | false
}
