import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
    width: 100%;
    margin-bottom: 30px;
`

const StyledLabel = styled.label<{ error?: boolean }>`
    display: block;
    margin-bottom: 10px;
    font-weight: 700;
    color: ${({ error }) => (error ? "var(--red)" : "initial")};
`
const StyledInput = styled.input<{ error?: boolean }>`
    padding: 10px;
    border: ${({ error }) =>
        error ? "1px solid var(--red)" : "1px solid var(--light-gray)"};
    border-radius: 10px;
    width: 100%;
    font-size: var(--base-font);

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    [type="number"] {
        -moz-appearance: textfield;
    }

    &:focus {
        outline: none;
        border: 1px solid var(--medium-blue);
    }
`

const Input = ({
    label,
    value,
    type,
    min,
    max,
    onChange,
    placeholder,
    id,
    pattern,
    required,
    error,
}: props) => {
    return (
        <StyledWrapper>
            {label && (
                <StyledLabel
                    htmlFor={id}
                    dangerouslySetInnerHTML={{ __html: label }}
                    error={error}
                />
            )}

            <StyledInput
                onChange={e => onChange(e.target.value)}
                autoComplete="on"
                type={type}
                value={value}
                placeholder={placeholder}
                minLength={min}
                maxLength={max}
                id={id}
                pattern={pattern}
                required={required}
                error={error}
            />
        </StyledWrapper>
    )
}

export default Input

interface props {
    label?: string
    value: any
    type: "text" | "password" | "email" | "number"
    min?: number
    max?: number
    onChange: (target: any) => void
    placeholder: string
    id: string
    pattern?: string
    required?: boolean
    error?: boolean
}
