import bcrypt from "bcryptjs"

const salt = bcrypt.genSaltSync(10)

export const encryptPassword = (password: string) => {
    return bcrypt.hashSync(password, salt)
}

export const decryptPassword = (password: string, hash: string) => {
    return bcrypt.compareSync(password, hash)
}
